@import "../bourbon/bourbon";
@import "../mixins.scss";


/*
    Project Specialty
*/

// #Tips
.accordion
{
    // padding: 20px;

    /* accordion items */
    >*
    {
        // padding: 20px 0;
        // border-top: 1px dotted gray;

        /* label */

        >*:first-child
        {
            a
            {
                // font-size: 25px;
                // color: red;
                // text-decoration: none !important;
            }
        }

        /* content */
        >*:last-child
        {
            padding-top: 10px;
            font-size: 14px;

            ul
            {
                padding: 10px;
                list-style-type: disc;
                list-style-position: inside;
            }
            ol
            {
                padding: 10px;
                list-style-type: decimal;
                list-style-position: inside;
            }
        }


        &:not(.target)
        {
            >*:last-child
            {
                // padding: 20px 0;
            }
        }
    }

}

/*
    Compontent Specialty
*/

.accordion
{


    .transition
    {
        transition: all 0.25s ease-in-out
    }

    /* accordion items */
    >*
    {
        position: relative;
        @extend .flipIn;


        /* label */

        >*:first-child
        {
            @extend .no-select;

            i
            {
                position: absolute;
                right: 0;
                @extend .transition;
                pointer-events: none;

                &::before
                {
                    @extend .transition;
                    display: inline-block;
                }
            }
        }

        /* content */

        >*:last-child
        {
            overflow: hidden;
            max-height: 800px;
            @extend .transition;
            opacity: 1;
            transform: translate( 0 , 0 );
        }


        &:not(.active)
        {
            >*:first-child
            {
                i::before
                {
                    @include transform(rotate( -90deg ));
                }
            }


            >*:last-child
            {
                max-height: 0;
                opacity: 0;
                transform: translate( 0 , 50% );
            }
        }

    }
}
