@import "bourbon/bourbon";
@import "mixins.scss";

@include font-face("icons", "/public/fonts/icons", normal);

[class^="icon-"], [class*=" icon-"],
.icon:before {
  display: inline-block;
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

   /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.icon-arrow-down:before { content: "\E001" }
.icon-checkmark:before { content: "\E002" }
.icon-minus:before { content: "\E003" }
.icon-plus:before { content: "\E004" }
.icon-google:before { content: "\E005" }
.icon-calendar:before { content: "\E006" }
.icon-card:before { content: "\E007" }
.icon-close:before { content: "\E008" }
.icon-download:before { content: "\E009" }
.icon-info:before { content: "\E00A" }
.icon-arrow-right:before { content: "\E012" }
.icon-arrow:before { content: "\E013" }
.icon-balance:before { content: "\E014" }
.icon-facebook:before { content: "\E015" }
.icon-hiperlink:before { content: "\E017" }
.icon-login:before { content: "\E018" }
.icon-mol-full:before { content: "\E019" }
.icon-mol:before { content: "\E01A" }
.icon-search:before { content: "\E01B" }
.icon-step1:before { content: "\E01C" }
.icon-step2:before { content: "\E01D" }
.icon-step3:before { content: "\E01E" }


.icon-vFlip:before
{
  @include transform(scale(-1, 1));
  display: inline-block;
}