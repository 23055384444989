@import "../bourbon/bourbon";
@import "../mixins.scss";


.header
{
    background: url('/public/img/bg_mol.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-attachment: fixed;

    .wrapper
    {
        padding-top: 35px;
        padding-bottom: 1px;
    }


    .multipont
    {
        position: absolute;
        top: 8px;
        left: 0;


        @include respond-to(handheld)
        {
            position: inherit;
            text-align: center;
        }

        @include respond-to(desktop)
        {
            &.fixed
            {
                position: fixed;
                width: 1200px;
                pointer-events: none;
                left: 0;
                right: 0;
                z-index: 102;
                margin: 0 auto;
                top: 0;
            }
        }
    }

    .mol
    {
        position: absolute;
        top: 20;
        right: 0;
        border-left: 2px solid $gray-light;
        padding-left: 30px;

        @include respond-to(handheld)
        {
            display: none;
        }
    }

    .merit-menu
    {
        margin: 0 300px 0 auto;
        max-width: 780px;
        margin-bottom: 50px;
        min-height: 30px;

        .status
        {
            margin: 0 auto;
            display: table;
            border: 1px solid #c7c7c7;
            border-left-color: transparent;
            background: #eaeaea;


            >div
            {
                font-size: 12px;
                font-weight: bold;
                text-transform: uppercase;
                display: table-cell;
                padding: 5px 15px;
                cursor: pointer;
                vertical-align: middle;
                position: relative;

                &.profile span,
                &.logout span
                {
                    display: inline-block;
                    padding: 3px 8px;

                    &:hover
                    {
                        background-color: $green;
                        color: white;
                    }
                }

                .icon-login
                {
                    position: absolute;
                    background: #eaeaea;
                    padding: 12px;
                    border-radius: 50%;
                    top: -8px !important;
                    left: -20px !important;
                    right: auto !important;
                    border: 1px solid #c7c7c7;
                    font-size: 30px;
                }

                &.user
                {
                    position: relative;
                    text-transform: none;
                    font-size: 11px;
                    line-height: 13px;
                    font-weight: normal;
                    cursor: default;

                    span
                    {
                        display: inline-block;
                        max-height: 26px;
                        max-width: 100px;
                        overflow: hidden;
                    }
                }

                &.accounts
                {
                    cursor: default;

                    ul
                    {
                        display: none;
                        background: white;
                        position: absolute;
                        width: calc(100% + 1px);
                        top: -1px;
                        left: -1px;
                        z-index: 2;
                        box-sizing: border-box;
                        padding: 6px 24px 4px 43px;
                        border: 1px solid #c7c7c7;
                        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);

                        li
                        {
                            padding: 2px 2px;
                            display: block;

                            &:not(:hover)
                            {
                                &.active .number
                                {
                                    color: $green;
                                }
                            }

                            .blance
                            {
                                min-width: 80px;
                                display: inline-block;
                                text-align: right;
                            }

                            &:hover
                            {
                                color: white;
                                background-color: $green;
                            }
                        }
                    }

                    &.active
                    {
                        &:hover ul
                        {
                            display: block;

                            li
                            {
                                cursor: pointer;
                            }
                        }
                    }


                    i
                    {
                        z-index: 3;
                    }

                    .number
                    {
                        font-weight: normal;
                    }

                    .ballance
                    {
                        padding: 0 10px;
                    }
                }

                >i ~span
                {
                    padding-left: 30px;
                }

                &::after
                {
                    width: 1px;
                    display: inline-block;
                    background-color: #c7c7c7;
                    content: ' ';
                    position: absolute;
                    height: 12px;
                    top: calc(50% - 6px);
                    right: 0px;
                }

                &:last-child::after
                {
                    display: none;
                }
            }

            &:not(.active)
            {
                font-size: 0;
                border-color: $green;
                font-weight: normal;

                .icon-login
                {
                    font-size: 22px;
                    border-color: $green;
                    left: -10px !important;
                }
                >div
                {
                    font-size: 14px;
                    background: $green;
                    color: white;
                    font-weight: normal;

                    &.login
                    {
                        position: relative;

                        i
                        {
                            background-color: $green;
                        }
                    }

                    &:hover
                    {
                        background-color: $green-light;
                    }

                    &::after
                    {
                        background-color: white;
                    }
                }
            }

            i
            {
                font-size: 21px;
                position: absolute;

                &.icon-arrow-down
                {
                    position: relative;
                    font-size: 9px;
                }
            }


        }

        @include respond-to(handheld)
        {
            margin: 20px auto;

            .status >div
            {
                display: block;
                text-align: center;

                &::after
                {
                    display: none;
                }

                i
                {
                    display: none;

                    & ~ span
                    {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    // .top
    // {
    //     display: table;
    //     width: 100%;
    //     height: 120px;
    //
    //     >div
    //     {
    //         display: table-cell;
    //         vertical-align: top;
    //
    //         &:first-child
    //         {
    //             width: 20%;
    //         }
    //
    //         &:last-child
    //         {
    //             width: 20%;
    //         }
    //     }
    // }


// Menu
// ----------------------

	.menu
	{
        background-color: white;
        width: 100%;
        z-index: 100;
        box-sizing: border-box;

        @include respond-to(handheld)
        {
            display: none;
        }


        @include respond-to(desktop)
        {
            &.fixed
            {
                width: 1185px;
                position: fixed;
                top: 24px;
                // left: 0;
                margin: 0 auto;
                margin-left: 15px;
                box-shadow: 6px 5px 21px -8px rgba(0,0,0,0.75);


                ul
                {
                    // padding-left: 45px;
                }
            }
        }

		ul
		{

            // @include transition(padding .2s ease-in-out);
			// padding-left: 45px;
            font-size: 0; // li gap
			overflow: hidden;

            @include respond-to(handheld)
            {
                padding: 0;

                li
                {
                    width: 100% !important;
                }
            }
		}

		li
		{
			display: inline-block;
      width: 16.6%;

			&:last-child
			{
			}

			a
			{
				vertical-align: middle;
				display: inline-block;
                height: 50px;
				line-height: 50px;


		        color: #8b8c90;
				font-size: 14px;
				font-weight: bold;
				text-transform: uppercase;
				text-align: center;

                width: 100%;
                @include transition(all 0.4s ease-in-out);

				span
				{
					line-height: 14px;
					display: inline-block;
					vertical-align: middle;
				}

                // &:hover
                // {
                //     background-color: $gray-light;
                // }

                &.active
                {
                    background-color: $green !important;
                    color: white;
                }
			}


            &.highlighted a
            {
                background-color: $green-light;
                color: white;
            }
        }

	}

    .mobilbanner
    {
        display: none;

        @include respond-to(handheld)
        {
            display: block;
        }

        img{
            width: 100%;
        }
    }

    .banner
    {
        box-sizing: border-box;
        padding: 20px 900px 0px 20px;
        height: 270px;
        background: url('/public/img/topbanner.png') no-repeat center right;
        background-size: contain;
        margin: 30px 0 40px 0;

        @include respond-to(handheld)
        {
            background: none;
            height: auto;
            padding: 0 20px;
        }

        h1
        {
            font-weight: bold;
            font-size: 32px;
            line-height: 39px;
            color: $gray-dark;
            margin-bottom: 18px;

            span
            {
                color: $red;
            }
        }

        p
        {
            font-size: 14px;
        }
    }
}

#agip .header .banner
{
    background: url('/public/img/topbanner_premio.png') no-repeat center right;
}
