@import "./bourbon/bourbon";


// Base Size
// ----------------------

$baseFontSizeHandheld: 16px;
$baseFontSizeTablet:   16px;
$baseFontSizeDesktop:  21px;

$browser-context: 16;



// Color palette
// ----------------------

$green: #3cae58;
$green-light: #9fcf67;
$green-dark: #008663;
$red: #ed1c24;
$red-light: #ef3f38;
$gray: #939598;
$gray-light: #cccccc;
$gray-dark: #58585a;
$brown: #483b30;
$brown-light: #917967;



// Fonts
// ----------------------

$font: "Source Sans Pro";



// Responsive Mixin
// ----------------------

$break-small: 768px;
$break-large: 1024px;

@mixin respond-to($media)
{
    @if $media == handheld
    {
        @media only screen and (max-width: $break-small - 1) { @content; }
    }
    @else if $media == tablet
    {
        @media only screen and (min-width: $break-small) and (max-width: $break-large - 1) { @content; }
    }
    @else if $media == mobile
    {
        @media only screen and (max-width: $break-large - 1) { @content; }
    }
    @else if $media == desktop
    {
        @media only screen and (min-width: $break-large) { @content; }
    }
}



// Project function
// ----------------------

@mixin background-curve($color)
{
    position: absolute;
    height: 100%;
    display: inline-block;
    content: " ";

    background-image: url('data:image/svg+xml;utf8,
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 0 100 100">
        <path fill="#{$color}" d="M50.213,86.59C34.87,95.513,18.152,99.996,0,99.996h100V0.004c0,18.143-4.482,34.861-13.406,50.205
            C77.632,65.513,65.517,77.647,50.213,86.59z"/>
    </svg>');

    background-position: right -0.3px bottom 0px;
    background-repeat: no-repeat;
    background-size: contain;
}

@mixin background-cart($color)
{
    background-image: url('data:image/svg+xml;utf8,
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 0 100 100">
         <g transform="translate(15.000000, 13.000000)">
             <path fill="#{$color}" d="M32.748,78.654c-2.087,0-3.77-1.773-3.77-3.935c0-2.185,1.683-3.941,3.77-3.941
                 c2.067,0,3.77,1.776,3.77,3.941C36.518,76.881,34.815,78.654,32.748,78.654 M32.748,62.432c-6.646,0-12.039,5.511-12.039,12.287
                 C20.709,81.492,26.122,87,32.748,87c6.649,0,12.039-5.508,12.039-12.281C44.787,67.943,39.398,62.432,32.748,62.432"/>
             <path fill="#{$color}" d="M4.072,78.654c-2.087,0-3.77-1.773-3.77-3.935c0-2.185,1.683-3.941,3.77-3.941c2.067,0,3.77,1.776,3.77,3.941
                 C7.822,76.881,6.139,78.654,4.072,78.654 M4.072,62.432c-6.646,0-12.039,5.511-12.039,12.287
                 C-7.987,81.472-2.574,86.98,4.072,86.98s12.039-5.508,12.039-12.281C16.112,67.943,10.699,62.432,4.072,62.432"/>
             <path fill="#{$color}" d="M80.494-13H61.586c-1.784,0-3.366,1.164-3.931,2.876L42.739,35.645H4.437l-9.16-26.526h43.084
                 c2.291,0,4.133-1.857,4.133-4.161c0-2.307-1.842-4.164-4.133-4.164h-48.9c-3.407,0-4.58,2.754-3.911,5.531l11.368,32.951
                 l-8.044,12.916c-2.17,3.099-0.061,6.387,3.505,6.387h52.854c2.291,0,4.133-1.86,4.133-4.164c0-2.304-1.842-4.161-4.133-4.161
                 H-0.122l3.911-6.286h41.948c1.787,0,3.364-1.164,3.934-2.876L64.584-4.674h15.91c2.291,0,4.136-1.857,4.136-4.161
                 C84.63-11.143,82.785-13,80.494-13"/>
         </g>
    </svg>');

    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
}


@mixin background-image-gen($color, $file-name, $isSize100Percent: false, $position: center top, $repeat: no-repeat)
{
    background: $color url('/static/gfx/#{$file-name}') $position $repeat;

    @if $isSize100Percent
    {
        background-size: 100%;
    }
}

@mixin off-canvas-menu($x: 0, $y: 0)
{
    @include transform(translate($x, $y));
    @include transition(transform .4s ease-in-out);
    -webkit-transform: translate3d($x, $y, 0);
}

@mixin easeOutExpo($properties, $duration)
{
    $easingValue: "0.190, 1.000, 0.220, 1.000";

    @include transition($properties $duration cubic-bezier(unquote($easingValue)));
}





// Functions
// ----------------------



@mixin opacity($opacity)
{
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin border-radius($value)
{
    @include border-top-radius($value);
    @include border-right-radius($value);
    @include border-bottom-radius($value);
    @include border-left-radius($value);
}

@mixin rotate($value)
{
    @include transform(rotate($value));
}

@mixin stroke($width, $color)
{
    $width: $width + 0;
    $shadow: 0 0 0 transparent;
    $i: 0;
    $w: 1;

    @while ($i < $width)
    {
        $i: $i + 1;
        $j: 0;
        $w: $w + 2;

        @for $r from 1 through $w
        {
            @for $c from 1 through $w
            {
                $x: $c - ceil($w / 2);
                $y: $r - ceil($w / 2);

                $shadow: (#{$shadow}, #{$x}px #{$y}px 0 $color);
            }
        }
    }

    text-shadow: $shadow;
}


@mixin aspect-ratio($width, $height)
{
    position: relative;
    &:before
    {
        display: block;
        content: " ";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .content
    {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
