.btn,
[class^="btn-"]
{
    display: inline-block;
    text-decoration: none;
    color: $gray;
    font-size: 18px;
    padding: 8px 30px;
    text-transform: uppercase;
    border-radius: 8px;
    border: 1px solid $gray;
    text-align: center;
    cursor: pointer;
    @include transition(all .2s ease-in-out);

    &:hover
    {
        @extend .btn-green;
    }
}

.btn-green
{
    border-color: #3cae58;
    color: #49b363;
    border-color: #9dd6ab;
    // color: #9dd6ab;
    // border-width: 2px;

    &:hover,
    &.active
    {
        background-color: #3cae58;
        color: white;
    }
}

.btn-primary
{
    // text-transform: uppercase;
}

.btn-secondary
{
    // text-transform: uppercase;
    // background: $blue-extra-light;
    // color: $blue-dark;
    //
    // &:hover
    // {
    //     background: $blue-dark;
    //     color: white;
    // }
}

.button-small
{
    position: relative;
    color: white;
    background: $green-light;
    display: inline-block;
    padding: 6px 50px 6px 20px;
    margin: 10px 0;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;

    background-image: url('data:image/svg+xml;utf8,
        <svg version="1.0" id="Layer_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 15.606 15.796" xml:space="preserve">
        <g>
        	<path fill="none" d="M30-22.102C16.166-22.102,4.526-12.735,1.058,0h14.548v15.796H1.058C4.526,28.531,16.166,37.898,30,37.898
        		c16.569,0,30-13.431,30-30S46.569-22.102,30-22.102z"/>
        	<path fill="#52a864" d="M15.606,0H1.058C0.373,2.517,0,5.164,0,7.898s0.373,5.381,1.058,7.898h14.548V0z"/>
        </g>
        </svg>');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    @include transition(all .2s ease-in-out);


    i
    {
        position: absolute;
        right: 5%;
        top: calc(50% - 7px);
        @include transform(rotate( -90deg ));
        @include transition(all .2s ease-in-out);
    }

    &:hover
    {
        background: $green;
        padding-right: 35px;
        padding-left: 35px;

        i
        {
            right: -15px;
        }
    }
}
