@import "../bourbon/bourbon";
@import "../mixins.scss";



.footer
{
    background-color: $green;
    background-image: linear-gradient(-169deg, #58b75c, #3ea155);
    color: white;
    font-size: 14px;

    a
    {
        color: white;
        text-decoration: none;

        &:hover
        {
            text-decoration: underline;
            color: white !important;
        }
    }

    .wrapper
    {
        @include respond-to(handheld)
        {
            padding: 0 em(20);
        }
    }

    .wrapper >div
    {
        display: table;
        width: 100%;

        >div
        {
            display: table-cell;
            vertical-align: top;


            &:first-child
            {
                width: 30%;
                text-align: left;
            }

            &:last-child
            {
                width: 70%;
                text-align: right;
            }
        }
    }


    .top
    {
        padding: 30px 0;
        border-bottom: 2px solid #5eaf6a;

        .logo span
        {
            margin-left: 100px;
        }

        .multipont-logo
        {
            position: absolute;
            margin-top: -30px;
        }

        i
        {
            font-size: 25px;
        }

        @include respond-to(handheld)
        {
            font-size: 120%;

            >div:first-child
            {
                display: none;
            }
        };
    }

    .bottom
    {
        font-size: 12px;
        padding: 10px 0 0 0;

        i
        {
            margin-right: 5px;
        }


        @include respond-to(handheld)
        {
            font-size: 120%;

            div.logo
            {
                width: 60% !important;
            }

            img
            {
                width: 100%;
            }
        };
    }


    ul
    {
        li
        {
            display: inline-block;

            a
            {
                display: inline-block;
                padding: 0 10px;
            }

            &:not(:last-child)::after
            {
                content: " ";
                display: inline-block;
                width: 1px;
                height: 10px;
                background-color: white;

            }
        }

        @include respond-to(handheld)
        {
            li
            {
                display: block;
                text-align: center;
                padding-bottom: em(10);
            }

            li::after
            {
                display: none !important;
            }
        };
    }

}
