@import "../bourbon/bourbon";
@import "../mixins.scss";

#agip,
#home
{
    .modal
    {
        .close
        {
            @include stroke(1, white);
            color: #3cae58;
            text-decoration: none;
            top: 15px;
            font-size: 50px;
            font-weight: normal;
        }

        &.embed
        {
            display: block;
            position: static;
            opacity: 1;
            padding: 50px 0 200px 0;

            .close
            {
                display: none;
            }

            .modal-container
            {
                width: 100%;
                background: none;
                box-shadow: none;
            }
        }
    }

    .modal-container
    {
        width: 620px;
        max-width: 90vw;
        font-size: 18px;
        font-weight: normal;
        color: #999999;

        *
        {
            vertical-align: bottom;
        }

        .preload
        {
            margin: 200px auto;
            text-align: center;
        }

        .title
        {
            background-color: #3cae58;
            color: white;
            text-transform: uppercase;
            padding: 15px;
            margin: 2px;
            font-size: 20px;
        }

        .model-content-wrapper
        {
            padding: 25px 15px;

            // checkbox compontent //

            >.table >.row >div:first-child
            {
                width: 40%;

                @include respond-to(mobile)
                {
                    width: 100%;
                    margin-top: em(10);
                }
            }

            >.table >.row >div:last-child input,
            >.table >.row >div:last-child >div,
            >.table >.row >div:last-child .table >div >div,
            >.table >.row >div:last-child .table >div input
            {
                font-size: 20px !important;
            }

            .field
            {
                margin-top: 20px;
                vertical-align: top;

                .options >div
                {
                    width: 100% !important;
                }
            }

            p
            {
                cursor: default;
            }

            // --- //

            .table .table >div
            {
                padding: 0 10px;

                &:first-child
                {
                    padding-left: 0;
                }
            }

            .table.fixed >div
            {
                display: inline-block;

                &.char-1
                {
                    width: 40px;
                }

                &.char-2
                {
                    width: 50px;
                }

                &.char-3
                {
                    width: 60px;
                }

                &.char-4
                {
                    width: 70px;
                }

                &.char-8
                {
                    width: 110px;
                }
            }

            .icon-calendar
            {
                color: #3cae58;
                font-size: 24px;
            }


            .page-number
            {
                color: #3cae58;
                font-size: 40px;
                font-weight: bold;
                float: right;
            }

            .subtitle
            {
                color: #666666;
                font-size: 20px;
                margin-bottom: 10px;
                margin-top: 0px;
                line-height: 140%;

                & ~ .subtitle
                {
                    margin-top: 50px;
                }
            }

            .lead
            {
                color: #666666;
                font-size: 16px;
                padding: 8px 0 24px 0;
            }

            .caption
            {
                margin-top: 20px;
                font-size: 60%;
                line-height: 130%;
            }

            .error,
            .guide
            {
                background-color: $green-light;
                color: white;
                padding: 20px;
                padding-top: 30px;
                margin-top: -10px;

                // triangle
                @extend .backround-embed;
                background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100">
                    <polygon fill="#FFFFFF" stroke-width="0" points="50,50 0,0 100,0"></polygon>
                </svg>');
                background-size: 45px;
                background-position: top left 6%;

                .caption
                {
                    font-size: 80%;
                    margin-top: 5px;
                }

                *
                {
                    color: white;
                }
            }

            .error
            {
                background-color: $red-light;
                margin-bottom: 30px;
            }

            .button-container
            {
                text-align: center;
                margin: 35px 0 5px 0;

                .btn
                {
                    padding-left: 80px;
                    padding-right: 80px;
                }
            }

            .next,
            .back
            {
                color: #3cae58;
                text-decoration: underline;
                font-size: 14px;
                cursor: pointer;

                i
                {
                    position: relative;
                    top: -3px;
                    margin-right: 5px;
                    @include transform(rotate( 90deg ));
                }
            }

            .next
            {
                float: right;

                i
                {
                    @include transform(rotate( -90deg ));
                }
            }

        }

    }

    .modal-overlay
    {
        background-color: #9fcf67;

        &.in
        {
            opacity: .4;
        }
    }


    .modal
    {
        &.balance
        {
            .subtitle
            {
                margin-bottom: 30px;
                margin-top: 20px;
            }
        }

        &.profile
        {
            .panel-title
            {
                color: $green-dark;
                padding: 0 0 20px 20px;
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
            }

            .panel
            {
                background-color: white;
            }

            .panel-content
            {
                padding: 20px;
            }

            .tabs
            {
                width: 100%;
                padding: 0px;
                display: table;
                background-color: $green;

                @media (max-width: 640px) {
                    display: block;
                }

                .tab
                {
                    display: table-cell;
                    padding: 12px 30px;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 14px;
                    color: white;
                    vertical-align: middle;

                    @media (max-width: 640px) {
                        display: block;
                    }

                    &:hover
                    {
                        background-color: $green-light;
                        cursor: pointer;
                    }

                    &.active
                    {
                        color: $green;
                        font-weight: bold;
                        background-color: white;
                    }
                }
            }

            .profile-balance
            {
                .table >div
                {
                    font-size: 14px;
                    line-height: 180%;
                    vertical-align: middle;

                    @media (max-width: 640px) {
                        display: block;
                    }
                }
            }

            .profile-history
            {
                // search field
                div:first-child div:last-child
                {
                    text-align: right;
                }

                thead th
                {
                    font-size: 14px !important;
                    font-weight: bold !important;
                }

                tbody td:nth-child(3),
                thead th:nth-child(3)
                {
                    width: 30%;
                }

                tbody td
                {
                    vertical-align: middle;
                }

                tbody tr:nth-child(odd) td
                {
                    background-color: rgba(159, 207, 103, 0.35) !important;
                }
            }
        }
    }
}




.modal-overlay
{
    position: fixed;
    display: none;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 988;
    background-color: #000;
    @include transition(opacity .4s ease-in-out);

    &.in
    {
        opacity: .6;
    }
}

.modal
{
    position: fixed;
    display: none;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    @include transition(opacity .4s ease-in-out);

    &.in
    {
        opacity: 1;
    }

    .close
    {
        color: white;
    }
}

.modal-wrapper
{
    display: table;
    width: 100%;
    height: 100%;
}

.modal-vertical
{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.modal-container
{
    position: relative;
    margin: 0 auto;
    display: inline-block;
    text-align: left;
    // max-width: 780px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    @include transition(all .2s ease-in-out);

    .close
    {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 35px;
        text-align: center;
        font-weight: bold;
        -webkit-font-smoothing: subpixel-antialiased;
        color: white;

        &:hover
        {
            text-decoration: none;
        }
    }


    @include respond-to(mobile)
    {
        width: 100%;
        // height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}
