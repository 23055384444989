.consent
{
  padding-top: 40px;

  .privacy
  {
    margin-bottom: -20px;

    .field > div:last-child
    {
      padding: 10px 0 20px 40px;
    }
  }

  label
  {
    color: #666666 !important;
  }

  .options > div
  {
    padding-bottom: 10px;

    &:hover
    {
      label
      {
        color: black !important;
      }
    }
  }

}

.terms
{
  color: #666666;
  font-size: 16px;
  padding: 10px 4px 4px 4px;

  a
  {
    color: $green;
  }
}
