@import "../bourbon/bourbon";
@import "../mixins.scss";


/*
    Project Specialty
*/

// #Tips .carousel
// {
//     .navbar
//     {
//         a
//         {
//             i
//             {
//                 background-color: $green-dark;
//                 border: 2px solid $green-dark;
//                 width: em(8);
//                 height: em(8);
//             }
//
//             &.active
//             {
//                 i
//                 {
//                     background-color: white;
//                 }
//             }
//         }
//     }
// }

/*
    Compontent Specialty
*/

.carousel
{
    margin: 0;
    position: relative;
    @include clearfix;

    .carousel-mask
    {
        // float: left;
        // position: relative;
        overflow: hidden;
        width: 100%;
        height: auto;
        white-space: nowrap;
    }

    ul
    {
        margin: 0;
        position: relative;
        left: 0;

        &.animate
        {
            @include transition(left .8s ease-in-out);
        }
    }

    li
    {
        display: inline-block;
        vertical-align: top;
        float: none;

        *
        {
            white-space: normal;
        }

        a
        {
            display: block;
            position: relative;
        }
        img
        {
            width: 100%;
        }
    }

    .arrows
    {
        position: absolute;
        right: 5%;
        @include calc(top, "50% - " em(20));
        width: em(20);
        height: em(20);
        border-radius: 50%;
        background-color: black;
        display: none;
        z-index: 260;
        cursor: pointer;


        &.enabled
        {
            display: block;
        }

        &.left
        {
            right: auto;
            left: 5%;
            @include transform(scale(-1, 1));
        }

        @include respond-to(desktop)
        {
            right: -5%;

            &.left
            {
                right: auto;
                left: -5%;
            }

        }

        i
        {
            color: white;
            font-size: em(60);

            &:hover
            {
                color: black;
            }
        }
    }

    &:hover
    {
        .arrows
        {
            @include opacity(1);
        }
    }


    .navbar
    {
        margin-top: em(5);
        text-align: center;

        a
        {
            display: inline-block;
            margin: 0;
            padding: em(0) em(5);
            cursor: pointer;

            i
            {
                display: block;
                background-color: gray;
                @include transition(all .2s ease-in-out);
                border-radius: 50%;
                width: em(15);
                height: em(15);
            }

            &.active
            {
                i
                {
                    background-color: white;
                }
            }

        }
    }
}

.carousel-scroll
{
    overflow: hidden;

    .carousel-mask
    {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}
