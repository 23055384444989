@import "../bourbon/bourbon";
@import "../mixins.scss";


h2
{
    font-weight: bold;
    font-size: 18px;
    color: $green-dark;
    text-transform: uppercase;
    text-align: left;
    padding: 10px;
    padding-top: 40px;
    line-height: 120%;

    @include respond-to(handheld)
    {
        font-size: 22px;
        text-align: center;
    }

    &.white
    {
        color: white;
    }
}

.href
{
    text-decoration: underline;
    color: $green-dark;
    cursor: pointer;
}









.section
{
	width: 100%;
	margin: 0 auto;
	padding: 0 0 20px 0;
	// min-height: 400px;
}

.wrapper
{
    width: 1200px;
    margin: 0 auto;
    position: relative;

    @include respond-to(mobile)
    {
        width: 100%;
    }
}



/* SECTIONS */


.section
{
    &.card-request
    {
        font-size: 14px;
        color: $gray-dark;
        text-align: center;

        background: white url("/public/img/bg_multipont.svg");
        background-repeat: no-repeat;
        background-size: 170%;
        background-position-x: 56%;
        background-position-y: 30%;

        .container
        {
            display: table;
            width: 100%;
            box-sizing: border-box;
            // border: 1px solid $gray-light;
            background-color: white;

            @include respond-to(handheld)
            {
                display: block;
            }

            >div
            {
                display: table-cell;
                width: 33.3%;
                padding: 10px;
                padding-top: 20px;
                cursor: pointer;

                @include respond-to(handheld)
                {
                    display: block;
                    width: 100%;

                    img
                    {
                        width: 90%;
                    }
                }
            }

            a
            {
                text-decoration: none;
                color: $gray-dark;
            }
        }

        .label
        {
            padding: 10px 0 5px 0;
            text-transform: uppercase;
            font-weight: bold;
        }

        .desc
        {
            padding: 5px 50px;
        }
    }




    &.promotions
    {
        background-color: $green-light;
        background-image: linear-gradient(35deg, $green-light, #427e4d);
        position: relative;

        .boubles
        {
            position: absolute;
            width: 100%;
            height: 100%;

            background: transparent url("/public/img/boubles1.svg");
            background-repeat: no-repeat;
            background-size: 110%;
            background-attachment: fixed;
            background-position: center center;

            @include opacity(.4);
        }



        ul
        {
            font-size: 0;
        }

        li
        {
            font-size: 26px;
            text-align: center;
            // padding: 130px 0;
            width: 100%;
            color: white;
            cursor: pointer;
            // background: white;

            // &:nth-child(2n)
            // {
            //     background-color: $green-light;
            // }
        }

        .arrows
        {
            background-color: white;
            text-align: center;

            i
            {
                margin-top: 7px;
                font-size: 12px;
                color: $green-light;
                @include transform(rotate( -90deg ));
            }


            &:hover
            {
                background-color: $green-light;

                i
                {
                    color: white;
                }
            }
        }

        .navbar
        {
            i
            {
                font-size: 70%;
                background-color: $green-dark;
            }

            .active i
            {
                background-color: white;
            }
        }

    }



    &.advantage
    {
        background: transparent url("/public/img/bg_multipont.svg");
        background-repeat: no-repeat;
        background-size: 170%;
        background-position-x: 56%;
        background-position-y: 30%;

        .container
        {
            padding: 25px;
            width: 100%;
            background-color: white;
            text-align: center;
            font-size: 14px;

            .table
            {
                margin-top: 20px;

                @include respond-to(handheld)
                {
                    >div
                    {
                        display: block;
                        width: 100% !important;
                        box-sizing: border-box;
                        background-size: 100% !important;
                        padding: 30px 0px 60px 0px !important;
                        background: none !important;
                    }
                }
            }

            h3
            {
                margin-bottom: 5px;
                font-size: 18px;
                line-height: 120%;

                @include respond-to(handheld)
                {
                    font-size: 22px;
                }
            }


            .title
            {
                font-weight: bold;
                color: $red;
                font-size: 20px;
                text-transform: uppercase;
            }


            .table >div
            {
                width: 33.3%;
                padding: 30px 80px 10px 80px;
                position: relative;
                background: url('/public/img/bg_step_next.jpg') center 16px no-repeat;

                &:last-child
                {
                    background-image: url('/public/img/bg_step.jpg');
                }

                i
                {
                    border-radius: 50%;
                    color: white;
                    box-sizing: border-box;
                    background-image: linear-gradient(135deg, #86ba60, #9dce66);
                    background-color: #9dce66;
                    @include transition(all .2s ease-in-out);
                    width: 130px;
                    height: 130px;
                    overflow: hidden;
                }

                i.number
                {
                    left: 0;
                    top: 0;
                    position: absolute;
                    padding-top: 13px;
                    width: 46px;
                    height: 46px;
                }
                i[class^="icon-"]
                {
                    // padding-left: 30px;
                    padding-top: 30px;
                    font-size: 70px;
                    // text-shadow: 2px 4px rgba(0,0,0,0.15);
                    @include transition(all .2s ease-in-out);
                }

                .title
                {
                    margin: 15px 0;
                }

                .desc
                {
                    margin-bottom: 15px;
                }

                &:hover
                {
                    i
                    {
                        // background-image: linear-gradient(35deg, #86ba60, #9dce66);
                        background: #86ba60;

                        &[class^="icon-"]
                        {
                            // padding-left: 0;
                            padding-top: 21.5px;
                            font-size: 80px;
                            text-shadow: 2px 4px rgba(0,0,0,0.15);
                        }
                    }

                    // .btn
                    // {
                    //     @extend .btn:hover;
                    // }
                }
            }
        }

    }

    &.station-finder
    {
        background: none;

        #StationFinder
        {
            height: 500px;

            .search-wrapper
            {
                position: absolute;
                z-index: 2;
                width: 100%;
            }
        }

        .search
        {
            position: relative;
            margin-top: 30px;

            input
            {

                width: 250px;
                box-sizing: border-box;
                padding: 5px 10px;
                font-weight: normal;
                color: $gray-dark;
                font-size: 16px;
            }
        }

        .icon-search
        {
            position: absolute;
            left: 222px;
            top: 5px;
            color: $green-light;

        }

        .search-helper
        {
            display: inline-block;
            background: white;
            max-width: 400px;
            min-width: 250px;
            margin-top: 2px;
            border: 1px solid #cccccc;

            .label
            {
                font-style: italic;
                padding: 5px;
                color: $gray;
                font-weight: normal;
                font-size: 12px;
            }

            .item
            {
                a
                {
                    text-decoration: none;
                    color: $gray-dark;
                    padding: 5px;
                    display: block;
                }

                &.selected
                {
                    background: $green-light;

                    a
                    {
                        color: white;
                    }
                }
            }
        }



        .map
        {
            height: 500px;
            background: gray;
        }

        .marker
        {
            width: 25px;
            height: 25px;
            background-color: black;
            border-radius: 50%;

            *
            {
                display: none;
            }
        }

        .tray
        {
            position: relative;
            top: -63px;
            margin-bottom: -63px;
            background-color: #9fcf67;
            padding: 12px;

            a
            {
                text-decoration: none;
                color: white;

                &:hover
                {
                    color: $green;
                    // text-decoration: underline;
                    //
                    .icon-arrow-right
                    {
                        margin-left: 5px;
                        margin-right: 0;
                    }
                }
            }

            >div
            {
                vertical-align: middle;
            }

            >div:last-child
            {
                text-align: right;
            }

            .icon-arrow-right
            {
                margin-right: 5px;
                font-size: 14px;
                @include transition(all .2s ease-in-out);
            }

            .icon-hiperlink
            {
                position: relative;
                top: 2px;
                margin-left: 8px;
                font-size: 18px;
            }


            @include respond-to(handheld)
            {
                top: -13px;
                margin-bottom: -13px;

                >div
                {
                    display: block;
                    text-align: center !important;

                    &:first-child
                    {
                        font-size: 120%;
                        margin-bottom: em(10);
                    }
                }
            }
        }
    }


    &.faq
    {
        .accordion
        {
            background-color: white;
            padding: 15px;
            padding-top: 0;

            >div
            {
                padding-top: 15px;
            }

            h3
            {
                border-bottom: 2px solid #eaeaea;
                padding-bottom: 2px;

                a
                {
                    padding-top: 10px;
                    display: block;
                    color: #58585a;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: bold;
                    text-decoration: none;
                    margin-right: 40px;

                    &:hover
                    {
                        color: $green;
                    }
                }

                i
                {
                    background-color: $green-light;
                    color: white;
                    border-radius: 2px;
                    padding: 10px;
                    font-size: 12px;
                }
            }

            p
            {
                font-size: 14px;
                color: #939598;
                padding: 5px 0;
            }

            >div.target
            {
                p
                {
                }

                h3 i
                {
                    background-color: $gray;
                }

                h3 a
                {
                    color: $green;
                }
            }
        }
    }

}



.content
{
    .section.card-request
    {
        .container
        {
            text-align: left;

            h3
            {
                padding: 10px;
                color: $green-dark;
                font-weight: bold;

            }

            p
            {
                padding: 10px;
            }

            a
            {
                color: $green-dark;
                text-decoration: underline;
            }

            ul
            {
                list-style: disc;
                padding: 10px 40px;
            }


        }
    }
}
